<template>
	<div class="wallet_info">
		<div class="tabs">
			<div class="item" :class="type == 'balance' ? 'active':''" @click="changeType('balance')">余额<div class="line"></div></div>
			<div class="item" :class="type == 'detail' ? 'active':''" @click="changeType('detail')">明细<div class="line"></div></div>
		</div>
		<template v-if="type=='balance'">
			<div class="my_wallet" v-loading="loading">
				<div class="item">
					<div class="tit">余额</div>
					<div class="money">{{(parseFloat(this.balanceInfo.balance) + parseFloat(this.balanceInfo.balance_money)).toFixed(2)}}</div>
					<div class="action" @click="getRechargeAccountType()">充值</div>
				</div>
				<div class="item">
					<div class="tit">可提现</div>
					<div class="money">{{ balanceInfo.balance_money }}</div>
					<div class="action" @click="getWithDrawConfig()">提现</div>
				</div>
				<div class="item">
					<div class="tit">不可提现</div>
					<div class="money">{{ balanceInfo.balance }}</div>
					<div class="action hidden">提现</div>
				</div>
			</div>
			<div class="recharge" id="recharge_show" v-if="recharge_show">
				<div class="top">
					<div class="item">
						<div class="tit">充值方式</div>
						<el-select v-model="recharge_type" placeholder="请选择">
							<el-option v-for="(item,index) in recharge_list" :key="item.value" :label="item.value" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="item">
						<div class="tit">充值金额</div>
						<input type="number" v-model="face_value">
					</div>
				</div>
				<div class="bottom">
					<div class="btn" @click="toRecharge()">确定</div>
				</div>
			</div>
			<div class="recharge" id="withdraw_show" v-if="withdraw_show">
				<div class="top">
					<div class="item">
						<div class="tit">提现账户</div>
						<el-select v-model="withdraw_account" placeholder="请选择" >
							<el-option v-for="(item,index) in account_list" :key="item.showName" :label="item.showName" :value="item.id">
								<div class="type">{{item.showName}}</div>
								<div v-if="item.id!='new'" class="iconfont iconshanchu" @click.stop="deleteMyAccount(item.id)"></div>
							</el-option>
						</el-select>
					</div>
					<div class="item">
						<div class="tit">提现金额</div>
						<input type="text" v-model="apply_money">
					</div>
				</div>
				<div class="bottom">
					<div class="btn" @click="applyWithdraw()">确定</div>
				</div>
			</div>
			<div class="recharge" v-if="withdraw_account=='new'">
				<div class="top">
					<div class="item">
						<div class="tit">姓名</div>
						<input type="text" v-model="realname">
					</div>
					<div class="item">
						<div class="tit">手机号</div>
						<input type="text" v-model="mobile">
					</div>
					<div class="item">
						<div class="tit">账号类型</div>
						<el-select v-model="withdraw_type" placeholder="请选择">
							<el-option v-for="(item,index) in account_type" :key="item" :label="item" :value="index">
							</el-option>
						</el-select>
					</div>
					<div class="item" v-if="withdraw_type=='bank'">
						<div class="tit">银行名称</div>
						<input type="text" v-model="branch_bank_name">
					</div>
					<div class="item">
						<div class="tit">提现账号</div>
						<input type="text" v-model="bank_account">
					</div>
				</div>
				<div class="bottom">
					<div class="btn" @click="addAccount()">确定</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="contract_tabs">
				<div>来源</div>
				<div>金额</div>
				<div>详细说明</div>
				<div>时间</div>
			</div>
			<div class="contract_list" v-if="accountList.length">
				<div class="conract_item" v-for="(item,index) in accountList" :key="index">
					<div>{{item.type_name}}</div>
					<div>{{item.account_data}}</div>
					<div>{{item.remark}}</div>
					<div>{{$util.timeStampTurnTime(item.create_time)}}</div>
				</div>
			</div>
			<div class="empty_list" v-else>
				暂无明细
			</div>
			<div class="pager">
				<el-pagination 
					background 
					:pager-count="5" 
					:total="total" 
					layout="prev,pager,next,jumper,total"
					:current-page.sync="currentPage" 
					:page-size.sync="pageSize" 
					@size-change="handlePageSizeChange" 
					@current-change="handleCurrentPageChange" 
					hide-on-single-page
				></el-pagination>
			</div>
		</template>
		<el-dialog class="pay_qrcode" :show-close="false" :visible.sync="dialogVisible" :close-on-click-modal="false">
			<div class="tit">{{recharge_type=='alipay'?'支付宝':'微信'}}<div class="line"></div></div>
			<img class="qrcode" :src="qrcode_img" />
			<div class="tips">打开{{recharge_type=='alipay'?'支付宝':'微信'}}支付</div>
			<i class="el-icon-error" @click="closePayQrcode()"></i>
		</el-dialog>
	</div>
</template>

<script>
	import { balance, balanceDetail } from "@/api/member/account"
	import { rechargeType, createRecharge, rechargePay, rechargeStatus, base64Qrcode, withdrawConfig, memberAccountList, deleteMemberAccount, applyWithdraw, accountType, addAccount, memberAccount } from '@/api/resource'
	export default {
		data() {
			return {
				type: 'balance',
				loading:true,
				balanceInfo: {
				    balance: 0,
				    balance_money: 0
				},
				recharge_show:false,
				withdraw_show:false,
				recharge_list:[],
				recharge_type:'',
				face_value:'',
				timer:'',
				qrcode_img:'',
				dialogVisible:false,
				min_withdraw:0,
				account_list:[],
				withdraw_account:'',
				apply_money:'',
				flag:false,
				account_type:'',
				withdraw_type:'',
				realname:'',
				mobile:'',
				branch_bank_name:'',
				bank_account:'',
				currentPage:1,
				pageSize:10,
				total:0,
				accountList:[],
				account_loading:true,
			}
		},
		props: {
			rechargeShow: {
				type: Boolean,
				default: false
			},
		},
		created() {
			this.getAccount();
			this.getMemberAccountList();
			this.getAccountType();
			if( this.rechargeShow ){
				this.recharge_show = this.rechargeShow ;
				this.getRechargeAccountType();
			}
		},
		watch: {
			withdraw_account(newValue, oldValue) {
				if(newValue=='new'){
					this.realname = '';
					this.mobile = '';
					this.withdraw_type = '';
					this.branch_bank_name = '';
					this.bank_account = '';
				}
			}
		},
		methods: {
			scrollToCenter(ID){
				this.$nextTick(()=>{
					window.scrollTo({
						'top': document.getElementById(ID).offsetTop - ( window.innerHeight / 2 ) + ( document.getElementById(ID).clientHeight / 2 ),
						'behavior': 'smooth'
					})
				})
			},
			closePayQrcode(){
				this.dialogVisible = false;
			},
			refresh() {
				this.account_loading = true;
				this.getBalanceDetail();
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			getBalanceDetail(){
				memberAccount({
					page_size: this.pageSize,
					page: this.currentPage,
					account_type: "balance,balance_money"
					// account_type: "commission"
				})
				.then(res=>{
					if(res.code>=0){
						this.accountList = res.data.list
						this.total = res.data.count
						this.accountList.forEach(item => {
							item.time = this.$util.timeStampTurnTime(item.create_time)
						})
						this.account_loading = false;
					}
				})
			},
			addAccount(){
				var phonereg = /^1[3-9]\d{9}$/;
				if(!this.realname){
					this.$message.error('请填写真实姓名');
					return;
				}
				if(!this.mobile){
					this.$message.error('请填写手机号');
					return;
				}
				if(!phonereg.test(this.mobile)){
					this.$message.error('手机号格式不正确');
					return;
				}
				if(!this.withdraw_type){
					this.$message.error('请选择账户类型');
					return;
				}
				if(this.withdraw_type=='bank' && (!this.branch_bank_name)){
					this.$message.error('请填写银行支行名称');
					return;
				}
				if(!this.bank_account){
					this.$message.error('请填写提现账号');
					return;
				}
				if(this.flag) return;
				this.flag = true;
				addAccount({
					realname:this.realname,
					mobile:this.mobile,
					withdraw_type:this.withdraw_type,
					branch_bank_name:this.branch_bank_name,
					bank_account:this.bank_account,
				})
				.then(res=>{
					if(res.code>=0){
						this.$message.success('添加成功');
						this.getMemberAccountList();
						this.withdraw_account = '';
						this.flag = false;
					}else{
						this.flag = false;
						this.$message.success(res.message || '添加失败');
					}
				})
				.catch(err=>{
					this.flag = false;
					this.$message.success(err.message || '添加失败');
				})
			},
			getAccountType(){
				accountType()
				.then(res=>{
					if(res.code>=0){
						this.account_type = res.data;
					}
				})
			},
			applyWithdraw(){
				if(!this.withdraw_account){
					this.$message.error('请选择提现账户');
					return;
				}
				if(this.withdraw_account=='new'){
					this.$message.error('请先添加提现账户');
					return;
				}
				if(!this.apply_money){
					this.$message.error('请填写提现金额');
					return;
				}
				if(Number(this.apply_money).toFixed(2)<this.min_withdraw){
					this.$message.error('最少需要提现'+this.min_withdraw+'元');
					return;
				}
				if(this.flag) return;
				this.flag = true;
				var data = {
					apply_money:Number(this.apply_money).toFixed(2),
					transfer_type:'',
					realname:'',
					bank_name:'',
					account_number:'',
					mobile:'',
				};
				this.account_list.forEach(item=>{
					if(item.id == this.withdraw_account){
						data['transfer_type'] = item.withdraw_type;
						data['realname'] = item.realname;
						data['bank_name'] = item.branch_bank_name;
						data['realname'] = item.realname;
						data['account_number'] = item.bank_account;
						data['mobile'] = item.mobile;
					}
				})
				applyWithdraw(data)
				.then(res=>{
					if(res.code>=0){
						this.$message.success('申请成功,请等待审核');
						this.withdraw_show = false;
						this.flag = false;
						this.getAccount()
					}else{
						this.$message.error(res.message);
						this.flag = false;
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
					this.flag = false;
				})
			},
			deleteMyAccount(id){
				deleteMemberAccount({
					id:id
				})
				.then(res=>{
					if(res.code>=0){
						if(id==this.withdraw_account) this.withdraw_account = '';
						this.getMemberAccountList()
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			getMemberAccountList(){
				memberAccountList({
					page:1,
					page_size:0,
				})
				.then(res=>{
					if(res.code>=0){
						this.account_list = [{showName:'新增账号',id:'new'}];
						res.data.list.forEach(item=>{
							if(item.withdraw_type=='bank'){
								item.showName = item.branch_bank_name+'('+item.bank_account.substring(item.bank_account.length-4)+')'
							}
							if(item.withdraw_type=='alipay'){
								item.showName = '支付宝 ('+item.bank_account.substring(item.bank_account.length-4)+')'
							}
							if(item.withdraw_type=='wechatpay'){
								item.showName = '微信 ('+item.bank_account.substring(item.bank_account.length-4)+')'
							}
						})
						this.account_list.unshift(...res.data.list);
					}
				})
			},
			getWithDrawConfig(){
				withdrawConfig()
				.then(res=>{
					if(res.code>=0){
						this.withdraw_account = '';
						this.apply_money = '';
						this.min_withdraw = res.data.value.min;
						this.withdraw_show = true;
						this.recharge_show = false;
						this.scrollToCenter('withdraw_show')
					}
				})
			},
			getRechargeStatus(out_trade_no){
				rechargeStatus({
					out_trade_no:out_trade_no
				})
				.then(res=>{
					if(res.code>=0){
						if(res.data.pay_status){
							this.$message.success('支付成功')
							this.dialogVisible = false;
							this.recharge_show = false;
							this.face_value = '';
							this.recharge_type = '';
							this.getAccount()
							clearTimeout(this.timer);
						}else{
							this.timer = setTimeout(()=>{
								this.getRechargeStatus(out_trade_no);
							},1000)
						}
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			toRecharge(){
				if(!this.recharge_type){
					this.$message.error('请选择充值方式')
					return;
				}
				if(!this.face_value){
					this.$message.error('请填写充值金额')
					return;
				}
				if(Number(this.face_value).toFixed(2)<=0){
					this.$message.error('充值金额不能小于0元')
					return;
				}
				createRecharge({
					recharge_id:0,
					face_value: Number(this.face_value).toFixed(2)
				})
				.then(res=>{
					if(res.code>=0){
						var out_trade_no = res.data;
						// 1705917448128961000
						var data = {
							out_trade_no:res.data,
							pay_type:this.recharge_type,
						}
						if(this.recharge_type =='alipay'){
							data.app_type = 'h5';
							data.app_type_name = 'H5';
						}
						rechargePay(data)
						.then(res=>{
							if(res.code>=0){
								if(res.data.type=='url'){
									base64Qrcode({
										text:res.data.data
									})
									.then(res=>{
										if(res.code>=0){
											this.qrcode_img = res.data;
											this.dialogVisible = true;
										}
									})
								}else{
									this.qrcode_img = res.data.qrcode;
									this.dialogVisible = true;
								}
								this.getRechargeStatus(out_trade_no);
							}else{
								this.$message.error(res.message);
							}
						})
						.catch(err=>{
							this.$message.error(err.message);
						})
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			getRechargeAccountType(){
				rechargeType()
				.then(res=>{
					if(res.code>=0){
						this.recharge_list = [];
						if(res.data.pay_type.indexOf('alipay')!= -1){
							this.recharge_list.push({id:'alipay',value:'支付宝'});
						}
						if(res.data.pay_type.indexOf('wechatpay')!=-1){
							this.recharge_list.push({id:'wechatpay',value:'微信'});
						}
						this.recharge_type = '';
						this.face_value = '';
						this.recharge_show = true;
						this.withdraw_show = false;
						this.withdraw_account = '';
						this.scrollToCenter('recharge_show');
					}
				})
			},
			getAccount(){
				balance({ account_type: "balance,balance_money" })
				.then(res => {
				    if (res.code == 0 && res.data) {
				        this.balanceInfo = res.data
				    }
				    this.loading = false
				})
				.catch(err => {
				    this.loading = false
				    this.$message.error(err.message)
				})
			},
			changeType(type) {
				this.type = type;
				if(this.type=='balance'){
					this.getAccount();
					this.recharge_show = false;
					this.withdraw_show = false;
					this.withdraw_account = '';
				}else{
					this.getBalanceDetail();
				}
			},
		},
	}
</script>
<style lang="scss">
	.wallet_info{
		.pager{
			margin-top: 30px;
			.el-pagination{
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn-prev{
					margin-right: 15px !important;
				}
				.btn-next{
					margin-left: 15px !important;
				}
				.btn-prev,.btn-next{
					min-width: 20px;
					height: 20px;
					margin: 0;
					color: #30373D !important;
					font-weight: bolder !important;
					background-color: transparent;
				}
				.el-pagination__jump{
					margin-left: 20px;
					color: #86909C;
					font-size: 14px;
					line-height: 11px;
					display: flex;
					align-items: center;
					height: auto;
					.el-pagination__editor{
						margin: 0 8px;
						padding: 0;
						height: auto;
						width: auto;
						.el-input__inner{
							height:auto;
							min-width: auto;
							border: none;
							color: #30373D;
							padding: 3px 2px 2px;
							line-height: 14px;
							font-size: 14px;
						}
					}
				}
				.el-pagination__total{
					margin-left: 10px;
					color: #86909C;
					font-size: 14px;
					line-height: 21px;
					height: 21px;
				}
				.el-pager{
					display: flex;
					align-items: center;
					.btn-quicknext,.btn-quickprev{
						height: 21px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
					}
					.number{
						padding: 0;
						color: #30373D;
						line-height: 11px;
						height: auto;
						min-width: auto;
						padding: 5px 6px;
						font-size: 14px;
						margin: 0;
						font-weight: 500;
						margin-right: 10px;
						background-color: transparent;
						&:last-child{
							margin-right: 0;
						}
						&:not(.disabled).active{
							color: #30373D;
							font-weight: bolder;
							background-color: #fff;
							border-radius: 1px;
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss">
	.pay_qrcode{
		.el-dialog{
			margin-top: 0 !important;
			top: 50%;
			transform: translateY(-50%);
			max-width: 554px;
			border-radius: 10px;
		}
		.el-dialog__header{
			padding:0;
		}
		.el-dialog__body{
			position: relative;
			padding: 76px 134px 86px;
			border-radius: 10px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.tit{
				width: 100%;
				border-bottom: 1px solid rgba(48, 55, 61, 0.10);
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-bottom: 25px;
				position: relative;
				.line{
					width: 34px;
					height: 2px;
					background-color: #30373D;
					border-radius: 6px;
					position: absolute;
					bottom: 0;
					transform: translateY(50%);
				}
			}
			.qrcode{
				width: 224px;
				border-radius: 3px;
				display: block;
				margin-top: 30px;
			}
			.tips{
				margin-top: 30px;
				color: #999;
				font-size: 13px;
				line-height: 15px;
			}
			.el-icon-error{
				color: rgba(48, 55, 61, 0.50);
				font-size: 18px;
				line-height: 18px;
				position: absolute;
				right: 20px;
				top: 20px;
				cursor: pointer;
			}
		}
	}
	.wallet_info .el-select-dropdown.el-popper{
		.el-select-dropdown__item{
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			.type{
			}
			.iconshanchu{
				margin-left: 20px;
				font-size: 14px;
				line-height: 14px;
			}
		}
	}
	.recharge{
		.el-input.el-input--suffix{
			display: flex;
			align-items: center;
			justify-content: center;
			.el-input__inner{
				border: none;
				background-color: #F7F8FB;
				border-radius: 5px;
				padding: 0;
				color: #30373D;
				font-size: 13px;
				line-height: 15px;
				height: 32px;
				// width: 50px;
				// min-width: 50px;
				// max-width: 100px;
				text-align: center;
				font-family: "PingFang SC";
			}
			.el-input__suffix{
				position: relative;
				top: 0;
				right: 0;
				.el-select__caret.el-input__icon.el-icon-arrow-up{
					width: auto;
					line-height: 32px;
					color: #30373D;
					&.is-reverse{
						transform: rotateZ(180deg);
					}
					transform: rotateZ(0);
					&::before{
						content: "\e790";
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
.empty_list{
	text-align: center;
	margin-top: 20px;
}
.pager{
	margin-top: 30px;
	.el-pagination{
		text-align: center;
	}
}
.tabs{
	display: flex;
	align-items: center;
	justify-content: center;
	.item{
		color: #30373D;
		font-size: 19px;
		line-height: 23px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 58px;
		cursor: pointer;
		&:last-child{
			margin-right: 0;
		}
		&.active{
			color: #FF3300;
			.line{
				background-color: #FF3300;
			}
		}
		.line{
			margin-top: 8px;
			width: 34px;
			height: 3px;
			background-color: transparent;
			border-radius: 5px;
		}
	}
}
.my_wallet{
	background-color: #fff;
	border-radius: 10px;
	padding: 30px 253px 30px 342px;
	margin-top: 30px;
	.item{
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.action.hidden{
			visibility: hidden;
		}
		&:last-child{
			margin-bottom: 0;
		}
		.tit{
			flex: 1;
			font-size: 15px;
			line-height: 21px;
		}
		.money{
			text-align: center;
			width: 425px;
			font-size: 13px;
			line-height: 32px;
			background-color: rgba(247, 248, 251, 0.20);
			border-radius: 5px;
		}
		.action{
			margin-left: 20px;
			cursor: pointer;
			color: #30373D;
			font-size: 13px;
			line-height: 18px;
			padding: 7px 22px 7px 21px;
			border-radius: 5px;
			background-color: #F7F8FB;
		}
	}
}
.recharge{
	background-color: #fff;
	border-radius: 10px;
	margin-top: 30px;
	.top{
		padding: 30px 342px;
		.item{
			display: flex;
			align-items: center;
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 0;
			}
			.tit{
				min-width: 60px;
				color: #30373D;
				font-size: 15px;
				line-height: 21px;
				margin-right: 30px;
			}
			.el-select{
				padding: 0 20px 0 0;
				flex:1;
				background-color: #F7F8FB;
				border-radius: 5px;
			}
			input{
				flex: 1;
				background-color: #F7F8FB;
				border: none;
				outline: none;
				color: #30373D;
				font-size: 13px;
				line-height: 18px;
				padding: 7px 0;
				text-align: center;
				border-radius: 5px;
				font-family: "PingFang SC";
			}
		}
	}
	.bottom{
		border-top: 1px solid rgba(48, 55, 61, 0.10);
		padding: 30px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		.btn{
			color: rgba(48, 55, 61, 0.50);
			font-size: 15px;
			line-height: 21px;
			padding: 6px 43px;
			border-radius: 5px;
			background-color: #F7F8FB;
			cursor: pointer;
			&:hover{
				color: #fff;
				background-color: #30373D;
			}
		}
	}
}
.contract_tabs{
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px 20px;
	margin-bottom: 17px;
	margin-top: 30px;
	div{
		color: #30373D;
		font-size: 15px;
		line-height: 18px;
		font-weight: 600;
		box-sizing: border-box;
		word-break:break-all;
		&:nth-child(1){
			width: 10%;
		}
		&:nth-child(2){
			width: 17%;
			box-sizing: border-box;
			padding-right: 5%;
			text-align: right;
		}
		&:nth-child(3){
			flex:1;
			padding-right: 30px;
		}
		&:nth-child(4){
			width: 19%;
		}
	}
}
.contract_list{
	.conract_item{
		display: flex;
		align-items: baseline;
		padding: 17px 20px;
		background-color: #fff;
		border-radius: 8px;
		margin-bottom: 17px;
		word-break:break-all
		&:last-child{
			margin-bottom: 0;
		}
		div{
			color: #30373D;
			box-sizing: border-box;
			font-size: 15px;
			line-height: 18px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			&:nth-child(1){
				width: 10%;
			}
			&:nth-child(2){
				width: 17%;
				box-sizing: border-box;
				padding-right: 5%;
				text-align: right;
			}
			&:nth-child(3){
				flex:1;
				padding-right: 30px;
			}
			&:nth-child(4){
				width: 19%;
			}
		}
	}
}
</style>