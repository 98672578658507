<template>
	<div class="buyer_contract" v-loading="loading">
		<div class="tabs">
			<div class="item" :class="current_state == 1 ? 'active':''" @click="chooseMode(1)">结算<div class="line"></div></div>
			<div class="item" :class="current_state == 0 ? 'active':''" @click="chooseMode(0)">预估<div class="line"></div></div>
			<div class="item" :class="current_state == 2 ? 'active':''" @click="chooseMode(2)">明细<div class="line"></div></div>
		</div>
		<div class="commision_info" v-if="member_detail && current_state != 2">
			<div class="tit">{{current_state==1 ? '结算佣金':'预估佣金'}}</div>
			<div class="money">{{current_state == 1 ? member_detail.commission : member_detail.commission_wait_settlement}}</div>
			<div class="btn" :class="current_state==1?'':'hidden'" @click="openWithdraw()">提现</div>
		</div>
		<template v-if="!withdraw_show && current_state != 2">
			<div class="contract_tabs">
				<div>项目</div>
				<div>金额</div>
				<div>分成</div>
				<div>订单号</div>
				<div>名称</div>
				<div>状态</div>
				<div class="export">日期
					<div class="btn" @click="exportExcel()">导出</div>
				</div>
			</div>
			<div class="contract_list" v-if="orderCommissionList.length">
				<div class="conract_item" v-for="(item,index) in orderCommissionList" :key="index">
					<div>{{item.category_name}}</div>
					<div>{{item.commission_money}}</div>
					<div>{{item.commission_rate}}%</div>
					<div>{{item.order_no}}</div>
					<div>{{item.goods_name}}</div>
					<div>{{item.order_status_name}}</div>
					<div>{{$util.timeStampTurnTime(item.create_time).split(' ')[0]}}</div>
				</div>
			</div>
			<!-- <div class="empty_list" v-else>
				暂无记录
			</div> -->
		</template>
		<template v-if="current_state==2">
			<div class="detail_tabs">
				<div>来源</div>
				<div>金额</div>
				<div>详细说明</div>
				<div>时间</div>
			</div>
			<div class="detail_list" v-if="detail_list.length">
				<div class="conract_item" v-for="(item,index) in detail_list" :key="index">
					<div>{{item.type_name}}</div>
					<div>{{item.account_data}}</div>
					<div>{{item.remark}}</div>
					<div>{{$util.timeStampTurnTime(item.create_time)}}</div>
				</div>
			</div>
			<!-- <div class="empty_list" v-else>
				暂无记录
			</div> -->
		</template>
		<div class="pager">
			<el-pagination 
				background 
				:pager-count="5" 
				:total="total" 
				layout="prev,pager,next,jumper,total"
				:current-page.sync="currentPage" 
				:page-size.sync="pageSize" 
				@size-change="handlePageSizeChange" 
				@current-change="handleCurrentPageChange" 
				hide-on-single-page
			></el-pagination>
		</div>
		<div class="recharge" v-if="withdraw_show">
			<div class="top">
				<div class="item">
					<div class="tit">提现账户</div>
					<el-select v-model="withdraw_account" placeholder="请选择" >
						<el-option v-for="(item,index) in account_list" :key="item.showName" :label="item.showName" :value="item.id">
							<div class="type">{{item.showName}}</div>
							<div v-if="item.id!='new' && item.id!='balance'" class="iconfont iconshanchu" @click.stop="deleteMyAccount(item.id)"></div>
						</el-option>
					</el-select>
				</div>
				<div class="item">
					<div class="tit">提现金额</div>
					<input type="text" v-model="apply_money">
				</div>
			</div>
			<div class="bottom">
				<div class="btn" @click="applyWithdraw()">确定</div>
			</div>
		</div>
		<div class="recharge" v-if="withdraw_account=='new'">
			<div class="top">
				<div class="item">
					<div class="tit">姓名</div>
					<input type="text" v-model="realname">
				</div>
				<div class="item">
					<div class="tit">手机号</div>
					<input type="text" v-model="mobile">
				</div>
				<div class="item">
					<div class="tit">账号类型</div>
					<el-select v-model="withdraw_type" placeholder="请选择">
						<el-option v-for="(item,index) in account_type" :key="item" :label="item" :value="index">
						</el-option>
					</el-select>
				</div>
				<div class="item" v-if="withdraw_type=='bank'">
					<div class="tit">银行名称</div>
					<input type="text" v-model="branch_bank_name">
				</div>
				<div class="item">
					<div class="tit">提现账号</div>
					<input type="text" v-model="bank_account">
				</div>
			</div>
			<div class="bottom">
				<div class="btn" @click="addAccount()">确定</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { orderCommissionList, memberDetail, commissionWithdraw, exportOrderCommissionList, memberAccountList, withdrawConfig, accountType, addAccount, deleteMemberAccount, memberAccount } from '@/api/resource';
	export default {
		data() {
			return {
				orderCommissionList:[],
				loading:true,
				currentPage: 1,
				pageSize: 10,
				total: 0,
				current_state:1,
				member_detail:'',
				withdraw_show:false,
				withdraw_account:'',
				account_list:[],
				apply_money:'',
				min_withdraw:0,
				flag:false,
				withdraw_type:'',
				realname:'',
				mobile:'',
				branch_bank_name:'',
				bank_account:'',
				account_type:'',
				detail_list:[],
			}
		},
		created() {
			this.getMemberDetail();
			this.getOrderCommissionList();
			this.getMemberAccountList();
			this.getWithDrawConfig();
			this.getAccountType();
		},
		watch: {
			withdraw_account(newValue, oldValue) {
				if(newValue=='new'){
					this.realname = '';
					this.mobile = '';
					this.withdraw_type = '';
					this.branch_bank_name = '';
					this.bank_account = '';
				}
			}
		},
		methods: {
			getCommissionDetail(){
				memberAccount({
					page_size: this.pageSize,
					page: this.currentPage,
					account_type: "commission"
				})
				.then(res=>{
					if(res.code>=0){
						this.detail_list = res.data.list
						this.total = res.data.count
						this.detail_list.forEach(item => {
							item.time = this.$util.timeStampTurnTime(item.create_time)
						})
						this.loading = false;
					}
				})
			},
			deleteMyAccount(id){
				deleteMemberAccount({
					id:id
				})
				.then(res=>{
					if(res.code>=0){
						if(id==this.withdraw_account) this.withdraw_account = '';
						this.getMemberAccountList()
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			addAccount(){
				var phonereg = /^1[3-9]\d{9}$/;
				if(!this.realname){
					this.$message.error('请填写真实姓名');
					return;
				}
				if(!this.mobile){
					this.$message.error('请填写手机号');
					return;
				}
				if(!phonereg.test(this.mobile)){
					this.$message.error('手机号格式不正确');
					return;
				}
				if(!this.withdraw_type){
					this.$message.error('请选择账户类型');
					return;
				}
				if(this.withdraw_type=='bank' && (!this.branch_bank_name)){
					this.$message.error('请填写银行支行名称');
					return;
				}
				if(!this.bank_account){
					this.$message.error('请填写提现账号');
					return;
				}
				if(this.flag) return;
				this.flag = true;
				addAccount({
					realname:this.realname,
					mobile:this.mobile,
					withdraw_type:this.withdraw_type,
					branch_bank_name:this.branch_bank_name,
					bank_account:this.bank_account,
				})
				.then(res=>{
					if(res.code>=0){
						this.$message.success('添加成功');
						this.getMemberAccountList();
						this.withdraw_account = '';
						this.flag = false;
					}else{
						this.flag = false;
					}
				})
				.catch(err=>{
					this.flag = false;
				})
			},
			getAccountType(){
				accountType()
				.then(res=>{
					if(res.code>=0){
						this.account_type = res.data;
					}
				})
			},
			applyWithdraw(){
				if(!this.withdraw_account){
					this.$message.error('请选择提现账户');
					return;
				}
				if(this.withdraw_account=='new'){
					this.$message.error('请先添加提现账户');
					return;
				}
				if(!this.apply_money){
					this.$message.error('请填写提现金额');
					return;
				}
				if(Number(this.apply_money).toFixed(2)<this.min_withdraw){
					this.$message.error('最少需要提现'+this.min_withdraw+'元');
					return;
				}
				if(this.flag) return;
				this.flag = true;
				var data = {
					apply_money:Number(this.apply_money).toFixed(2),
					transfer_type:'',
					realname:'',
					bank_name:'',
					account_number:'',
					mobile:'',
				};
				if(this.withdraw_account=='balance'){
					data['transfer_type'] = 'balance';
				}else{
					this.account_list.forEach(item=>{
						if(item.id == this.withdraw_account){
							data['transfer_type'] = item.withdraw_type;
							data['realname'] = item.realname;
							data['bank_name'] = item.branch_bank_name;
							data['realname'] = item.realname;
							data['account_number'] = item.bank_account;
							data['mobile'] = item.mobile;
						}
					})
				}
				commissionWithdraw(data)
				.then(res=>{
					if(res.code>=0){
						if(this.withdraw_account=='balance'){
							this.$message.success('申请成功');
						}else{
							this.$message.success('申请成功,请等待审核');
						}
						this.withdraw_show = false;
						this.flag = false;
						this.getMemberDetail();
						this.refresh();
					}else{
						this.$message.error(res.message);
						this.flag = false;
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
					this.flag = false;
				})
			},
			getWithDrawConfig(){
				withdrawConfig()
				.then(res=>{
					if(res.code>=0){
						this.min_withdraw = res.data.value.min;
					}
				})
			},
			openWithdraw(){
				this.withdraw_account = '';
				this.apply_money = '';
				this.withdraw_show = true;
			},
			getMemberAccountList(){
				memberAccountList({
					page:1,
					page_size:0,
				})
				.then(res=>{
					if(res.code>=0){
						this.account_list = [{showName:'余额',id:'balance'},{showName:'新增账号',id:'new'}];
						res.data.list.forEach(item=>{
							if(item.withdraw_type=='bank'){
								item.showName = item.branch_bank_name+'('+item.bank_account.substring(item.bank_account.length-4)+')'
							}
							if(item.withdraw_type=='alipay'){
								item.showName = '支付宝 ('+item.bank_account.substring(item.bank_account.length-4)+')'
							}
						})
						this.account_list.unshift(...res.data.list);
					}
				})
			},
			exportExcel(){
				exportOrderCommissionList({
					is_settlement:this.current_state
				})
				.then(res=>{
					if(res.code>=0){
						this.$message.success('下载成功');
						window.open(res.data.file_path);
					}else{
						this.$message.error(res.message)
					}
				})
			},
			getMemberDetail(){
				memberDetail()
				.then(res=>{
					if(res.code>=0){
						this.member_detail = res.data;
					}
				})
			},
			chooseMode(mode){
				this.current_state = mode;
				this.withdraw_account = '';
				this.withdraw_show = false;
				if(this.current_state!=2){
					this.getMemberDetail();
				}
				this.currentPage = 1;
				this.refresh();
			},
			toOrderDetail(id){
				this.$router.push('/member/order_detail?order_id='+id+'&role=agent');
			},
			getOrderCommissionList() {
				orderCommissionList({
					page:this.currentPage,
					page_size:this.pageSize,
					is_settlement:this.current_state
				})
				.then(res=>{
					if(res.code>=0){
						let list = [];
						if (res.code == 0 && res.data) {
							list = res.data.list;
							this.total = res.data.count;
						}
						this.orderCommissionList = list;
						this.loading = false;
					}else{
						this.loading = false;
					}
				})
			},
			refresh() {
				this.loading = true;
				if(this.current_state==2){
					this.getCommissionDetail();
				}else{
					this.getOrderCommissionList();
				}
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
		},
	}
</script>
<style lang="scss">
	
	.buyer_contract{
		.pager{
			margin-top: 30px;
			.el-pagination{
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				.btn-prev{
					margin-right: 15px !important;
				}
				.btn-next{
					margin-left: 15px !important;
				}
				.btn-prev,.btn-next{
					min-width: 20px;
					height: 20px;
					margin: 0;
					color: #30373D !important;
					font-weight: bolder !important;
					background-color: transparent;
				}
				.el-pagination__jump{
					margin-left: 20px;
					color: #86909C;
					font-size: 14px;
					line-height: 11px;
					display: flex;
					align-items: center;
					height: auto;
					.el-pagination__editor{
						margin: 0 8px;
						padding: 0;
						height: auto;
						width: auto;
						.el-input__inner{
							height:auto;
							min-width: auto;
							border: none;
							color: #30373D;
							padding: 3px 2px 2px;
							line-height: 14px;
							font-size: 14px;
						}
					}
				}
				.el-pagination__total{
					margin-left: 10px;
					color: #86909C;
					font-size: 14px;
					height: 21px;
					line-height: 21px;
				}
				.el-pager{
					display: flex;
					align-items: center;
					.btn-quicknext,.btn-quickprev{
						height: 21px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
					}
					.number{
						padding: 0;
						color: #30373D;
						line-height: 11px;
						height: auto;
						min-width: auto;
						padding: 5px 6px;
						font-size: 14px;
						margin: 0;
						font-weight: 500;
						margin-right: 10px;
						background-color: transparent;
						&:last-child{
							margin-right: 0;
						}
						&:not(.disabled).active{
							color: #30373D;
							font-weight: bolder;
							background-color: #fff;
							border-radius: 1px;
						}
					}
				}
			}
		}
	}
	
	.el-select-dropdown.el-popper{
		.el-select-dropdown__item{
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			.type{
			}
			.iconshanchu{
				font-size: 14px;
				margin-left: 20px;
				line-height: 14px;
			}
		}
	}
	.recharge{
		.el-input.el-input--suffix{
			display: flex;
			align-items: center;
			justify-content: center;
			.el-input__inner{
				border: none;
				background-color: #F7F8FB;
				border-radius: 5px;
				padding: 0;
				color: #30373D;
				font-size: 13px;
				line-height: 15px;
				height: 32px;
				// width: 50px;
				// min-width: 50px;
				// max-width: 100px;
				text-align: center;
				font-family: "PingFang SC";
			}
			.el-input__suffix{
				position: relative;
				top: 0;
				right: 0;
				.el-select__caret.el-input__icon.el-icon-arrow-up{
					width: auto;
					line-height: 32px;
					color: #30373D;
					&.is-reverse{
						transform: rotateZ(180deg);
					}
					transform: rotateZ(0);
					&::before{
						content: "\e790";
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>

.detail_tabs{
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px 11px;
	margin-bottom: 17px;
	margin-top: 30px;
	div{
		color: #30373D;
		font-size: 15px;
		line-height: 18px;
		font-weight: 600;
		box-sizing: border-box;
		word-break:break-all;
		&:nth-child(1){
			width: 19%;
		}
		&:nth-child(2){
			width: 19%;
		}
		&:nth-child(3){
			flex:1;
			padding-right: 30px;
		}
		&:nth-child(4){
			width: 20%;
		}
	}
}
.detail_list{
	.conract_item{
		display: flex;
		align-items: baseline;
		padding: 17px 11px;
		background-color: #fff;
		border-radius: 8px;
		margin-bottom: 17px;
		&:last-child{
			margin-bottom: 0;
		}
		div{
			color: #30373D;
			box-sizing: border-box;
			font-size: 15px;
			line-height: 18px;
			word-break:break-all;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			&:nth-child(1){
				width: 19%;
			}
			&:nth-child(2){
				width: 19%;
			}
			&:nth-child(3){
				flex:1;
				padding-right: 30px;
			}
			&:nth-child(4){
				width: 20%;
			}
			
		}
	}
}
.recharge{
	background-color: #fff;
	border-radius: 10px;
	margin-top: 30px;
	.top{
		padding: 30px 342px;
		.item{
			display: flex;
			align-items: center;
			margin-bottom: 30px;
			&:last-child{
				margin-bottom: 0;
			}
			.tit{
				min-width: 60px;
				color: #30373D;
				font-size: 15px;
				line-height: 21px;
				margin-right: 30px;
			}
			.el-select{
				padding: 0 20px 0 0;
				flex:1;
				background-color: #F7F8FB;
				border-radius: 5px;
			}
			input{
				flex: 1;
				background-color: #F7F8FB;
				border: none;
				outline: none;
				color: #30373D;
				font-size: 13px;
				line-height: 18px;
				padding: 7px 0;
				text-align: center;
				border-radius: 5px;
				font-family: "PingFang SC";
			}
		}
	}
	.bottom{
		border-top: 1px solid rgba(48, 55, 61, 0.10);
		padding: 30px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		.btn{
			color: rgba(48, 55, 61, 0.50);
			font-size: 15px;
			line-height: 21px;
			padding: 6px 43px;
			border-radius: 5px;
			background-color: #F7F8FB;
			cursor: pointer;
			&:hover{
				color: #fff;
				background-color: #30373D;
			}
		}
	}
}
.empty_list{
	text-align: center;
	margin-top: 20px;
}
.tabs{
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 21px;
	.item{
		color: #30373D;
		font-size: 19px;
		line-height: 23px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 58px;
		cursor: pointer;
		&:last-child{
			margin-right: 0;
		}
		&.active{
			color: #FF3300;
			.line{
				background-color: #FF3300;
			}
		}
		.line{
			margin-top: 8px;
			width: 34px;
			height: 3px;
			background-color: transparent;
			border-radius: 5px;
		}
	}
}
.buyer_contract{
	padding-top: 10px;
	.commision_info{
		padding: 30px 250px 30px 339px;
		border-radius: 10px;
		background-color: #fff;
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.tit{
			color: #30373D;
			font-size: 18px;
			line-height: 25px;
		}
		.money{
			color: #30373D;
			font-size: 13px;
			line-height: 18px;
		}
		.btn{
			color: #30373D;
			font-size: 13px;
			line-height: 18px;
			padding:7px 22px 7px 21px;
			background-color: #F7F8FB;
			border-radius: 5px;
			cursor: pointer;
			&.hidden{
				visibility: hidden;
			}
		}
	}
	.contract_tabs{
		display: flex;
		align-items: center;
		background-color: #fff;
		border-radius: 10px;
		padding: 17px 20px;
		margin-bottom: 17px;
		>div{
			color: #30373D;
			font-size: 15px;
			line-height: 18px;
			font-weight: 600;
			box-sizing: border-box;
			flex: 1;
			word-break:break-all;
			&:nth-child(1){
				flex: none;
				width: 10%;
			}
			&:nth-child(2){
				flex: none;
				padding-right: 5%;
				box-sizing: border-box;
				text-align: right;
				width: 16%;
			}
			&:nth-child(3){
				flex: none;
				width: 9%;
			}
			&:nth-child(4){
				flex: none;
				width: 13%;
			}
			&:nth-child(5){
				padding-right: 20px;
			}
			&:nth-child(6){
				flex: none;
				width: 8%;
			}
			&:nth-child(7){
				flex: none;
				width: 15%;
			}
			&.export{
				display: flex;
				align-items: center;
				.btn{
					margin-left: 33px;
					color: #30373D;
					font-size: 13px;
					line-height: 13px;
					padding: 8px 15px;
					border-radius: 3px;
					background-color: #F7F8FB;
					flex: 0;
					white-space: nowrap;
					cursor: pointer;
				}
			}
		}
	}
	.contract_list{
		.conract_item{
			display: flex;
			align-items: baseline;
			padding: 17px 20px;
			background-color: #fff;
			border-radius: 8px;
			margin-bottom: 17px;
			&:last-child{
				margin-bottom: 0;
			}
			div{
				color: #30373D;
				box-sizing: border-box;
				font-size: 15px;
				line-height: 18px;
				flex:1;
				word-break:break-all;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				&:nth-child(1){
					flex: none;
					width: 10%;
				}
				&:nth-child(2){
					flex: none;
					padding-right: 5%;
					box-sizing: border-box;
					text-align: right;
					width: 16%;
				}
				&:nth-child(3){
					flex: none;
					width: 9%;
				}
				&:nth-child(4){
					flex: none;
					width: 13%;
				}
				&:nth-child(5){
					padding-right: 20px;
				}
				&:nth-child(6){
					flex: none;
					width: 8%;
				}
				&:nth-child(7){
					flex: none;
					width: 15%;
				}
			}
		}
	}
}
</style>